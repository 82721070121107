import Footer from '../components/Footer';
import Header from '../components/Header';
import Bar from '../components/Bar';
import Streams from '../components/Streams';

function Home() {
    return (
        <div className='flex flex-col min-h-screen bg-no-repeat dark:bg-gradient-to-b dark:from-gray-800 dark:via-gray-900 dark:to-black dark:text-white bg-gradient-to-b from-white via-gray-100 to-gray-200 text-gray-900'>
            <div className='flex-grow'>
                <Header />
                <Bar />
                <Streams />
            </div>
            <Footer />
        </div>
    );
}

export default Home;