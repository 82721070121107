import React, { useEffect, useRef } from 'react';
import Logo from '../assets/img/Logo.png';
import { House, PersonLinesFill, Newspaper, Book, List } from 'react-bootstrap-icons';

const Header: React.FC = () => {
    const menuToggleRef = useRef<HTMLButtonElement>(null);
    const mobileMenuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleMenuToggleClick = () => {
            if(mobileMenuRef.current) {
                mobileMenuRef.current.classList.toggle('hidden');
            }
        };

        const handleWindowClick = (e: MouseEvent) => {
            if(e.target instanceof Node && menuToggleRef.current && mobileMenuRef.current && !menuToggleRef.current.contains(e.target) && !mobileMenuRef.current.contains(e.target)) {
                mobileMenuRef.current.classList.add('hidden');
            }
        };

        const handleDOMContentLoaded = () => {
            const scrollpos = localStorage.getItem('scrollpos');
            if(scrollpos) {
                window.scrollTo(0, parseInt(scrollpos));
            }
        };

        const handleBeforeUnload = () => {
            localStorage.setItem('scrollpos', window.scrollY.toString());
        };

        if(menuToggleRef.current) {
            menuToggleRef.current.addEventListener('click', handleMenuToggleClick);
        }
        window.addEventListener('click', handleWindowClick);
        document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);
        window.onbeforeunload = handleBeforeUnload;

        // Cleanup event listeners on component unmount
        return () => {
            if(menuToggleRef.current) {
                menuToggleRef.current.removeEventListener('click', handleMenuToggleClick);
            }
            window.removeEventListener('click', handleWindowClick);
            document.removeEventListener('DOMContentLoaded', handleDOMContentLoaded);
            window.onbeforeunload = null;
        };
    }, []);

    return (
        <header className="dark:bg-gray-800 py-8 bg-gray-200">
            <div className="container mx-auto flex justify-between items-center public-head-length:space-x-4">
                <div className="container mx-auto flex justify-between items-center public-head-length:space-x-4">
                    <a className="flex justify-center items-center space-x-4" href="https://panel.hope-rp.com/">
                        <img src={Logo} alt="Hope Logo" className="w-24 mx-auto" id="header-logo" />
                        <h1 className="text-xl text-center font-bold">Streams</h1>
                    </a>

                    <div className="hidden public-head-length:flex justify-center items-center space-x-4">
                        <a href="https://panel.hope-rp.com/" className="flex justify-center items-center"><House className="mr-1"/> Accueil</a>
                        <a href="https://panel.hope-rp.com/whitelist" className="flex justify-center items-center"><PersonLinesFill className="mr-1"/> Whitelist</a>
                        <a href="https://panel.hope-rp.com/news" className="flex justify-center items-center"><Newspaper className="mr-1"/> Weazel News</a>
                        <a href="https://panel.hope-rp.com/penalCode" className="flex justify-center items-center"><Book className="mr-1"/> Code pénal</a>
                    </div>

                    <div className="public-head-length:hidden">
                        <button ref={menuToggleRef} className="block text-gray-500 focus:outline-none text-3xl mx-5">
                            <List />
                        </button>
                        <div ref={mobileMenuRef} className="hidden absolute top-16 right-0 bg-gray-200 w-48 mt-2 py-2 rounded-lg z-50">
                            <a href="https://panel.hope-rp.com/" className="flex flex-row px-4 py-2 text-gray-700 hover:bg-gray-300"><House className="mt-1 mr-1"/> Accueil</a>
                            <a href="https://panel.hope-rp.com/whitelist" className="flex flex-row px-4 py-2 text-gray-700 hover:bg-gray-300"><PersonLinesFill className="mt-1 mr-1"/> Whitelist</a>
                            <a href="https://panel.hope-rp.com/news" className="flex flex-row px-4 py-2 text-gray-700 hover:bg-gray-300"><Newspaper className="mt-1 mr-1"/> Weazel News</a>
                            <a href="https://panel.hope-rp.com/penalCode" className="flex flex-row px-4 py-2 text-gray-700 hover:bg-gray-300"><Book className="mt-1 mr-1"/> Code pénal</a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;