import Logo from '../assets/img/Logo.png';

function Bar() {
    return (
        <div className="relative overflow-hidden z-10 w-full h-[21rem]">
            <iframe src="https://panel.hope-rp.com/public/background" title='Hope Background' className="object-cover w-full h-full bg-gradient-to-b from-blue-600 to-white dark:from-gray-800 dark:to-gray-900"/>
            <div className="absolute inset-0 flex items-center justify-center flex-col text-white">
                <img src={Logo} alt="Hope Logo" className='mx-auto h-32 w-auto' />
                <h1 className="lg:text-7xl md:text-6xl sm:text-5xl text-4xl font-bold" id="hope-title">Hope - GTARP</h1>
                <p className="text-2xl mt-3" id="hope-subtitle">Liste des streamer.euse.s du serveur 🩵</p>
            </div>
        </div>
    )
}

export default Bar;