import { Youtube, Twitter, Discord, Tiktok, House, Twitch, HeartFill, Reception4, BookHalf } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer className="static bottom-0 w-full dark:bg-gray-800 py-8 bg-gray-200">
            <div className="container mx-auto">
                <div className="flex justify-between items-start">
                    <div className="w-1/3">
                        <h1 className="text-2xl font-bold mb-4">Retrouvez-nous sur</h1>
                        <div className="mt-4">
                            <div className="flex items-center">
                                <span className="ml-2 font-semibold">&#x2022;</span>
                                <Discord className="ml-3"/>
                                <span className="ml-2">Discord : <a href="https://discord.gg/htHrGDTTSh" className="text-blue-400" target="_blank" rel="noopener noreferrer">Hope - GTARP</a></span>
                            </div>
                            <div className="flex items-center">
                                <span className="ml-2 font-semibold">&#x2022;</span>
                                <Twitter className="ml-3"/>
                                <span className="ml-2">Twitter : <a href="https://twitter.com/Hope_GTARP" className="text-blue-400" target="_blank" rel="noopener noreferrer">@Hope_GTARP</a></span>
                            </div>
                            <div className="flex items-center">
                                <span className="ml-2 font-semibold">&#x2022;</span>
                                <Youtube className="ml-3"/>
                                <span className="ml-2">YouTube : <a href="https://youtube.com/@Hope_GTARP" className="text-blue-400" target="_blank" rel="noopener noreferrer">@Hope_GTARP</a></span>
                            </div>
                            <div className="flex items-center">
                                <span className="ml-2 font-semibold">&#x2022;</span>
                                <Tiktok className="ml-3"/>
                                <span className="ml-2">TikTok : <a href="https://tiktok.com/@Hope_GTARP" className="text-blue-400" target="_blank" rel="noopener noreferrer">@Hope_GTARP</a></span>
                            </div>
                        </div>
                    </div>
                    <div className="w-1/3">
                        <h1 className="text-2xl font-bold mb-4">Raccourcis</h1>
                        <div className="mt-4">
                            <div className="flex items-center">
                                <span className="ml-2 font-semibold">&#x2022;</span>
                                <House className="ml-3"/>
                                <span className="ml-2"><a href="https://panel.hope-rp.com/" className="text-blue-400" rel="noopener noreferrer">Accueil</a></span>
                            </div>
                            <div className="flex items-center">
                                <span className="ml-2 font-semibold">&#x2022;</span>
                                <Twitch className="ml-3"/>
                                <span className="ml-2"><a href="#" className="text-blue-400" rel="noopener noreferrer">Streameur.euse.s du serveur</a></span>
                            </div>
                            <div className="flex items-center">
                                <span className="ml-2 font-semibold">&#x2022;</span>
                                <BookHalf className="ml-3"/>
                                <span className="ml-2"><a href="https://hope-rp.fandom.com/fr/wiki/Wiki_Hope_-_GTARP" target="_blank" className="text-blue-400" rel="noopener noreferrer">Wiki</a></span>
                            </div>
                            <div className="flex items-center">
                                <span className="ml-2 font-semibold">&#x2022;</span>
                                <HeartFill className="ml-3"/>
                                <span className="ml-2"><a href="https://panel.hope-rp.com/thanks" className="text-blue-400" rel="noopener noreferrer">Remerciements</a></span>
                            </div>
                            <div className="flex items-center">
                                <span className="ml-2 font-semibold">&#x2022;</span>
                                <Reception4 className="ml-3"/>
                                <span className="ml-2"><a href="https://uptime.hope-rp.com/" className="text-blue-400" target="_blank" rel="noopener noreferrer">État de l'infrastructure</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-6 dark:bg-gray-700 p-4 text-center dark:text-gray-300 bg-gray-300 text-gray-700" id="footer-copyright">
                &copy; hope-rp.com | Designed by <Link to={"https://github.com/Lytzeer"} target='_blank'>Lytzeer</Link> & Hope Team
            </div>
        </footer>
    )
}

export default Footer;