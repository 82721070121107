import React from 'react';
import { Link } from 'react-router-dom';
import Button_Off from '../assets/img/Button_Off.png';
import Button_On from '../assets/img/Button_On.png';

interface StreamCardProps {
    stream: Stream;
}

const OfflineStreamCard: React.FC<StreamCardProps> = ({ stream }) => {
    return (
        <div className='dark:bg-gray-800 bg-gray-300 p-4 rounded-2xl m-3 w-72 h-28 overflow-hidden' key={stream.ID}>
            <div className='flex justify-between'>
                <img src={stream.ProfileImageURL} alt="Streamer PP" className='w-20 h-20 rounded-full' />
                <div className='flex flex-col justify-center'>
                    <p className='font-bold text-lg mb-4 mr-0'>{stream.Channel}</p>
                    <div className='flex flex-row items-center py-2 px-6 bg-gray-600 rounded-2xl w-32 opacity-65 justify-center'>
                        <img src={Button_Off} alt="On Air Button" className='w-2 h-2' />
                        <p className='ml-2 font-bold justify-end text-end text-black dark:text-white'>ON AIR</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const OnlineStreamCard: React.FC<StreamCardProps> = ({ stream }) => {
    return (
        <Link to={`https://twitch.tv/${stream.Channel}`} className='w-96 m-1' target='_blank' rel='noreferrer'>
            <div key={stream.ID} className='dark:bg-[#212539] p-4 rounded-2xl mt-7 bg-gray-300'>
                <img src={`https://static-cdn.jtvnw.net/previews-ttv/live_user_${stream.Channel.toLowerCase()}.jpg?r=${stream.StartedAt}`} alt="Stream Preview" className='w-fit rounded-2xl' />
                <p className='flex my-4'>{stream.Title}</p>
                <div className='flex justify-between'>
                    <img src={stream.ProfileImageURL} alt="Streamer PP" className='w-20 h-20 rounded-full' />
                    <div className='flex flex-col'>
                        <p className='font-bold text-lg mb-4'>{stream.Channel}</p>
                        <div className='flex items-center py-2 px-6 bg-red-500 rounded-2xl'>
                            <img src={Button_On} alt="On Air Button" className='w-2 h-2' />
                            <a href={`https://www.twitch.tv/${stream.Channel}`} target='_blank' rel='noreferrer'>
                                <p className='ml-2 font-bold'>ON AIR</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export { OfflineStreamCard, OnlineStreamCard };