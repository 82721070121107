import useSWR from 'swr'
import { useEffect, useState } from 'react';
import { OfflineStreamCard, OnlineStreamCard } from './StreamCards';

function Streams() {
    const fetcher = (url: string) => fetch(url).then((res) => res.json())
    const { data, error, isLoading } = useSWR(
        process.env.REACT_APP_PANEL_API_URL,
        fetcher,
        {
            refreshInterval: 5000,
            refreshWhenHidden: false,
            refreshWhenOffline: false,
            revalidateOnFocus: true
        }
    )

    const [onlineStreams, setOnlineStreams] = useState<Stream[]>([])
    const [offlineStreams, setOfflineStreams] = useState<Stream[]>([])
    const [loading, setLoading] = useState(true)
    const [errorState, setErrorState] = useState(false)

    useEffect(() => {
        if (data) {
            const online = data.filter((stream: Stream) => stream.Live && stream.Display);
            const offline = data.filter((stream: Stream) => !stream.Live || !stream.Display);

            setOnlineStreams(online)
            setOfflineStreams(offline)
        }
    }, [data])

    useEffect(() => {
        if (!isLoading) {
            setLoading(false)
        }
    }, [isLoading])

    useEffect(() => {
        if (error) {
            setErrorState(true)
        } else {
            setErrorState(false)
        }
    }, [error])

    return (
        <div className='ml-10 mt-10 mb-10'>
            <div id='Streams_Online' className='flex'>
                <div id='Online'>
                    <h3 className='text-3xl font-bold ml flex'>Streams en ligne</h3>
                    {loading && !error && <p>Chargement...</p>}
                    {onlineStreams.length === 0 && !loading && !error && <p className='mt-4 ml-4'>Aucun stream en ligne</p>}
                    {errorState && <p>Une erreur lors de la récupération des streams...</p>}
                    <div className='flex flex-row flex-wrap'>
                        {onlineStreams.map((stream) => (
                            <OnlineStreamCard stream={stream} key={stream.ID} />
                        ))}
                    </div>
                </div>
            </div>
            <div className='flex mt-8'>
                <div className='flex-col'>
                    <h3 className='text-3xl font-bold ml flex'>Streams hors ligne</h3>
                    {loading && !error && <p>Chargement...</p>}
                    {errorState && <p>Une erreur lors de la récupération des streams...</p>}
                    <div className='flex flex-row flex-wrap'>
                        {offlineStreams.map((stream) => (
                            <OfflineStreamCard stream={stream} key={stream.ID} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Streams;